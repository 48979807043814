.partner {
    max-width: 1050px;
    margin:100px auto 117px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.partner-list {
    display: flex;
    flex:1;
    -ms-flex: none;
    justify-content: space-between;
    border:1px solid #eeeeee;
    padding:17px;
    margin-bottom: 30px;
}

@media (max-width: 970px) {
    .partner-list {
        flex-direction: column;
        align-items: center;
    }
}

.partner-img {
    width: 100%;
    max-width: 410px;
    height: 245px;
    object-fit: cover;
    /*background-color:#626262;*/
}

.partner-img img {
    width: 100%;
    max-width: 410px;
    height: 245px;
    object-fit: cover;
}

.partner-contents {
    display: flex;
    flex-direction: column;
    width: 505px;
    padding-right:30px;
    padding-top:30px;
}

@media (max-width: 970px) {
    .partner-contents {
        width: 100%;
        max-width: 410px;
        padding-right: 0;
    }
}

.partner-title {
    font-size:33px;
    font-weight: 500;
}
.partner-desc {
    font-size:15px;
    font-weight: 400;
    border-bottom: 1px solid #626262;
    padding:25px 0 15px 0;
}
.partner-cate {
    font-size:15px;
    font-weight: 400;
    padding-top:15px;
}
.partner-tel {
    font-size:30px;
    font-weight: 400;
    padding:20px 0;
    color:#c34040;
}