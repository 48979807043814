.join {
    max-width: 1050px;
    margin:73px auto 118px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.join-second {
    max-width: 1050px;
    margin:45px auto 147px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.join-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.join-title {
    font-size: 18px;
    font-weight: 500;
}


.join-header > .join-title {
    margin-right:13px;
}
.join-desc {
    font-size: 14px;
    font-weight: 300;
}

.join-process {
    margin-top:20px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #eeeeee;
    padding: 60px 90px;
}

.join-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    width: 25%;
    padding-bottom: 25%;
    border-radius: 50%;
    background-color:#FFF;
}
.join-circle > img {
    top:30%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}
.join-circle-contents {
    min-width: 126px;
    position:absolute;
    top:59%;
    left: 50%;
    transform: translateX(-50%);
}
.join-circle-contents > div {
    text-align: center;
    margin-bottom: 5px;
    font-size:17px;
}

.join-circle-contents > div:nth-child(2) {
    font-size:13px;
}
.join-next {
    display: flex;
    align-items: center;
    margin: 0 37px;
}

@media (max-width: 500px) {
    .join-process {
        margin-top:20px;
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #eeeeee;
        padding: 20px;
    }
    .join-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position:relative;
        width: 300px;
        padding-bottom: 300px;
        border-radius: 50%;
        background-color:#FFF;
    }

    .join-next {
        display: flex;
        margin: 10px 0;
    }

}

.join-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 40px;
}
.join-col {
    flex:1;
    padding-left: 10px;
}
.join-col > ol {
    padding-left: 0;
    margin-left: 30px;
    margin-top:13px;
}
.join-col > ol > li {
    font-weight: 300;
    padding:3px 0;
}