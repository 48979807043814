.org-row {
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
}
.org-box {
    border:4px solid #abc10b;
    min-width: 195px;
    height: 70px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.org-box.first {
    border-color: #e5e5e5;
    background-color:#e5e5e5;
}

.org-box.first div:first-child {
    font-size:14px;
}

.org-box.first div:nth-child(2) {
    font-size:18px;
}

.org-box.important {
    border:4px solid #abc10b;
    background-color:#abc10b;
    color:#FFF;
}
.org-box.hidden {
    border:0;
}

.col-line {
    border:1px solid #e5e3e4;
    height:57px;
    width:1px;
}

.row-line {
    border:1px solid #e5e3e4;
    height:1px;
    width:57px;
}
.row-line-hidden {
    border:1px solid #FFF;
    height:1px;
    width:57px;
}
.org-table {
    margin:0 auto 147px;
    max-width: 1050px;
    border:0;
}
.org-table th {
    height: 47px;
    background-color:#626262;
    color:#FFF;
    font-size:16px;
    font-weight:normal;
}
.org-table td {
    height: 47px;
    text-align:center;
    border-color:#eeeeee;
}
.org-table td[rowspan] {
    border-right:1px solid #eeeeee;
}