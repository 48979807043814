.greeting {
    max-width: 1150px;
    margin:100px auto 147px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.greeting-circle {
    display:flex;
    align-items: center;
    justify-content: center;
    color:#FFF;
    width:100px;
    height:100px;
    border-radius: 50%;
    background-color:#6a803a;
    margin-bottom: 22px;
}

.greeting-row {
    display:flex;
    align-items: center;
    justify-content: center;
    height:100px;
    position:relative;
}
.greeting-row-first {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position:relative;
}

@media (max-width: 500px) {
    .greeting-row {
        display:flex;
        align-items: center;
        justify-content: center;
        height:120px;
        position:relative;
    }
}

.greeting-col {
    position:relative;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
}

.greeting-col-first {
    display:flex;
    flex-direction: column;
    align-items: center;
    height:50px;
    padding: 0 40px;
    position:relative;
}
.greeting-col-last {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px 50px 40px;
    height:100%;
    position:relative;
}

.greeting-text-right {
    position:relative;
    font-size:16px;
    font-weight: 300;
    flex:1;
    line-height: 1.5;
}
.greeting-text-left {
    position:relative;
    font-size:16px;
    font-weight: 300;
    flex:1;
    text-align: right;
    line-height: 1.5;
}

.bold {
    font-size:21px;
    font-weight: normal;
}

.greeting-dot {
    border:4px solid #abc10b;
    border-radius: 50px;
    padding:2px;
    width: 5px;
}

.greeting-col-line {
    border:2px solid #e5e3e4;
    height:40px;
    width:0;
}
.greeting-col-line2 {
    border:2px solid #e5e3e4;
    width:0;
    height:100%;
}