.major {
    max-width: 1060px;
    margin:50px auto 147px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.major-row {
    display:flex;
}

.major-col {
    display:flex;
    max-width: 520px;
    flex:1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 39px 24px 35px 40px;
    margin: 20px 10px 0 0;
    background-color:#eeeeee;
    font-size: 16px;
}
.major-col:nth-child(n+2) {
    margin: 20px 0 0 10px;
}