.customoverlay {position:relative;left:5px;bottom:72px;border-radius:6px;border: 1px solid #ccc;border-bottom:2px solid #ddd;float:left;}
.customoverlay:nth-of-type(n) {border:0; box-shadow:0px 1px 2px #888;}
.customoverlay a {
    display:block;
    text-decoration:none;
    color:#000;
    text-align:center;
    border-radius:6px;
    font-size:14px;
    font-weight:bold;
    overflow:hidden;
    background: #d95050;
    background: #d95050 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;
}
.customoverlay .title {
    display:block;
    text-align:center;
    background:#fff;
    /*margin-right:35px;*/
    padding:10px 15px;
    font-size:14px;
    font-weight:bold;
}
.customoverlay:after {content:'';position:absolute;margin-left:-12px;left:50%;bottom:-12px;width:22px;height:12px;background:url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}
.radius_border{border:1px solid #919191;border-radius:5px;}
.custom_zoomcontrol {position:absolute;top:10px;right:10px;width:36px;height:80px;overflow:hidden;z-index:1;background-color:#f5f5f5;}
.custom_zoomcontrol span {display:block;width:36px;height:40px;text-align:center;cursor:pointer;}
.custom_zoomcontrol span img {width:15px;height:15px;padding:0;border:none;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 2px;
}
.custom_zoomcontrol span:first-child{border-bottom:1px solid #bfbfbf;}